/* eslint-disable require-jsdoc */
import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import './App.css';
import {Home} from './pages/Home/Home.jsx';
import GoogleAnalytics from './GoogleAnalytics';

function App() {
  return (
    <div>
      <GoogleAnalytics />
      <Router>
        <Routes>
          <Route path="/" element={<Home />}/>
  
        </Routes>
      </Router>

    </div>

  );
}

export default App;
